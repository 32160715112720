import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import {
	Seo,
	Layout
} from "../../components"

import ScrollWrapper from './ScrollWrapper'
import { LocomotiveScrollProvider } from "react-locomotive-scroll"

const HomepageTemplate = ({ data, pageContext }) => {
	const [ hasScrolled, setHasScrolled ] = useState(false)

  const { seoMetaFields } = data.page
  const metadata = seoMetaFields.wpFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const containerRef = useRef()

  return (
    <Layout hasScrolled={hasScrolled}>
      <Seo title={metadata.seoTitle} description={metadata.seoDescription} />

			<LocomotiveScrollProvider
				options={{
					smooth: true,
				}}
				containerRef={containerRef}
				watch={
					["teste"]
				}
			>
				<ScrollWrapper data={data} pageContext={pageContext} containerRef={containerRef} hasScrolled={hasScrolled} setHasScrolled={setHasScrolled} />
			</LocomotiveScrollProvider>
    </Layout>
  )
}

export const Query = graphql`
  {
    page: wpPage(slug: { eq: "home" }) {
      databaseId
      seoMetaFields {
        wpFields {
          languageValue {
            slug
            name
          }
          seoTitle
          seoDescription
        }
      }
      homePageFields {
        heroSection {
          languageValue {
            slug
            name
          }
          top{
            heading
            subheading
            paragraph1
            paragraph2
            mockupImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE,
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          bottom {
            videoThumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE,
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            videoLink
            videoTitle
            heading
            subheading
          }
        }
				howItWorksSectionText{
          languageValue {
            slug
            name
          }
          heading
          bettors {
            left {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            right {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          chats {
            content
						side
          }
					features {
          	feature1 {
							heading
							content
							screen {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
							screenMobile {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
						}
						feature2 {
							heading
							content
							screen {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
							screenMobile {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
						}
						feature3 {
							heading
							content
							screen {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
							screenMobile {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
						}
						feature4 {
							heading
							content
							screen {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
							screenMobile {
	            	localFile {
	              	childImageSharp {
	                	gatsbyImageData(
	                  	placeholder: NONE
	                    layout: FULL_WIDTH
	                  )
	                }
	              }
	            }
						}
					}
        }
        sectionBetting {
          languageValue {
            slug
            name
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
          heading
          links {
            link1
            link2
            link3
          }
        }
        sectionStories {
          languageValue {
            slug
            name
          }
          heading
          subheading
          label
        }
        sectionPlay {
          languageValue {
            slug
            name
          }
          banners {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
						imageMobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            linkUrl
          }
        }
        sectionDownload {
          languageValue {
            slug
            name
          }
          heading
          subheading
          buttons {
            appleLink
            appleImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            androidLink
            androidImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          mockup {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
    featuredPosts: allWpPost(
			limit: 3,
			filter: {
				status: {eq: "publish"},
				blogFields: {
					isFeatured: { eq: "Yes" },
					blogFields: {
						elemMatch: {
							blogTitle: {ne: null},
							blogImage: {
								localFile: {
									childImageSharp: {
										gatsbyImageData: {ne: "null"}
									}
								}
							},
							excerpt: {ne: null},
							languageValue: {
								slug: {ne: null}
							},
							readingTime: {ne: null}
						}
					}
				}
			}
    ) {
      edges {
        node {
          categories {
            nodes {
              databaseId
              slug
              name
              parentDatabaseId
            }
          }
          slug
          databaseId
          blogFields {
            blogFields {
              blogTitle
							blogImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 1074
											placeholder: NONE
											layout: CONSTRAINED
										)
									}
								}
							}
              excerpt
              languageValue {
                slug
              }
            }
          }
        }
      }
    }
		featuredWinners: allWpWinner(limit: 3,
      filter: { winnersPostFields: { featured: { eq: true } } }
    ) {
      edges {
        node {
          winnersPostFields {
            winnersFields {
              languageValue {
								name
                slug
              }
              name
              location
              excerpt
              copy
							secondaryImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 1074
											placeholder: NONE
											layout: CONSTRAINED
										)
									}
								}
							}
							thirdImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 1074
											placeholder: NONE
											layout: CONSTRAINED
										)
									}
								}
							}
							secondaryContent
							thirdContent
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default HomepageTemplate