import React, { useEffect, useState } from 'react'
import {
	Hero,
	Stories,
	VideoFrame,
	HowItWorks,
	BlogEntry,
	AdsCarousel,
	DownloadApp,
	Footer,
} from "../../components"

import { useLocomotiveScroll } from 'react-locomotive-scroll'
import { set } from 'js-cookie'

const ScrollWrapper = ({containerRef, data, pageContext, hasScrolled, setHasScrolled}) => {
	const [ blogActive, setBlogActive ] = useState(false)
	const [ howActive, setHowActive ] = useState(false)

	const { scroll } = useLocomotiveScroll()
	const { homePageFields } = data.page

	const hero = homePageFields.heroSection.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
	const howItWorks = homePageFields.howItWorksSectionText.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const play = homePageFields?.sectionPlay.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const betting = homePageFields.sectionBetting.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const stories = homePageFields.sectionStories.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const download = homePageFields.sectionDownload.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const blogPosts = data.featuredPosts.edges
  const storyPosts = data.featuredWinners.edges

	useEffect(() => {
		scroll?.on('scroll', function(e) {
			// Configuring navbar visibility
			if ( e.scroll.y === 0 ) {
				setHasScrolled(false)
			}

			if ( !hasScrolled ) {
				if ( e.scroll.y > 100 ) {
					setHasScrolled(true)
				}
			}

			// Configuring elements to appear
			if ( e.currentElements?.el2 ) {
				if ( e.scroll.y >= (e.currentElements.el2.middle.y) - 750 ) {
					setHowActive(true)
				} else {
					setHowActive(false)
				}
			}

			if ( e.currentElements?.el3 ) {
				if ( e.scroll.y >= (e.currentElements.el3.middle.y - 450) ) {
					setBlogActive(true)
				} else {
					setBlogActive(false)
				}
			}
		})
	}, [hasScrolled, scroll])

	const isPt = pageContext.lang.includes("pt")

	return (
		<main data-scroll-container ref={containerRef}>
			<Hero data={hero} />
			<VideoFrame data={hero} />
			<HowItWorks data={howItWorks} active={howActive} />
			<AdsCarousel data={play} hasContainer={true}/>
			{isPt && (<BlogEntry data={{betting, blogPosts}} language={pageContext.lang} active={blogActive} />)}
			{isPt && (<Stories data={{stories, storyPosts}} language={pageContext.lang} />)}
			<DownloadApp data={download} />
			<Footer lang="pt" style={{height: "600px"}} isHome={true}/>
		</main>
	)
}

export default ScrollWrapper
